import * as React from "react"
import styled from "styled-components"

import SocialLink from "./sociallink"

const SocialsContainer = styled.ul`
  float: right;
  list-style-type: none;
  list-style-position: none;
  padding: 20px;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const Socials = () => (
  <SocialsContainer>
    <SocialLink
      social="IG"
      href="https://www.instagram.com/detaildesignscostume/"
    />
    <SocialLink
      social="FB"
      href="https://www.facebook.com/detaildesignscostume"
    />
    <SocialLink social="LI" href="https://www.linkedin.com/in/beverleyhulme/" />
  </SocialsContainer>
)

export default Socials
