import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

const LogoContainer = styled.div`
  margin: 20px;
`

const Logo = () => (
  <LogoContainer>
    <StaticImage
      src="../images/logo.png"
      height={200}
      alt="Detail Designs"
      placeholder="blurred"
      layout="fixed"
    />
  </LogoContainer>
)

export default Logo
