import * as React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"

const NavItemContainer = styled.li`
  flex: 1;
  padding: 10px;
  font-size: 1.4em;
  text-decoration: none;
  transition: color 0.2s;
  color: #888;
  &:hover {
    color: #006666;
  }
`

const NavItem = ({ title, to }) => (
  <Link to={to} style={{ textDecoration: "none", color: "#888", flex: "1" }}>
    <NavItemContainer>{title}</NavItemContainer>
  </Link>
)

NavItem.propTypes = {
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
}

export default NavItem
