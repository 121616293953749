import * as React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const SubHeaderText = styled.h2`
  color: #888;
  font-size: 1.8em;
`

const SubHeader = ({ children }) => <SubHeaderText>{children}</SubHeaderText>

SubHeader.propTypes = {
  children: PropTypes.node.isRequired,
}

export default SubHeader
