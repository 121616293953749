import * as React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import Navbar from "./navbar"

const BannerContainer = styled.div`
  width: 100%;
  background: linear-gradient(to top right, #b7e7e2, #e6f5f4);
`

const Banner = ({ children }) => (
  <BannerContainer>
    <Navbar />
    {children}
  </BannerContainer>
)

Banner.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Banner
