import * as React from "react"
import styled from "styled-components"

import NavItem from "./navitem"

const NavContainer = styled.ul`
  list-style-type: none;
  list-style-positon: inside;
  padding: 10px;
  display: flex;
  flex-direction: row;
  width: 400px;
  justify-content: center;
  align-items: center;
  text-align: center;
`

const Navbar = () => (
  <NavContainer>
    <NavItem title="Home" to="/" />
    <NavItem title="Costumes" to="/costumes" />
    <NavItem title="About" to="/about" />
    <NavItem title="Contact" to="/contact" />
  </NavContainer>
)

export default Navbar
