import * as React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import Logo from "./logo"
import SubHeader from "./subheader"

const HeroContainer = styled.div`
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Hero = ({ header }) => (
  <HeroContainer>
    <Logo />
    <SubHeader>{header}</SubHeader>
  </HeroContainer>
)

Hero.propTypes = {
  header: PropTypes.string.isRequired,
}

export default Hero
