import * as React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import Instagram from "../images/icons/instagram.svg"
import Facebook from "../images/icons/facebook.svg"
import LinkedIn from "../images/icons/linkedin.svg"

const SocialLinkContainer = styled.li`
  margin: 5px;
`

const SocialLink = ({ social, href }) => {
  let icon
  switch (social) {
    case "IG":
      icon = <Instagram className="icon" />
      break
    case "FB":
      icon = <Facebook className="icon" />
      break
    case "LI":
      icon = <LinkedIn className="icon" />
      break
    default:
      icon = <Facebook className="icon" />
  }
  return (
    <SocialLinkContainer>
      <a href={href} target="_blank" rel="noopener noreferrer">
        {icon}
      </a>
    </SocialLinkContainer>
  )
}

SocialLink.propTypes = {
  social: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
}

export default SocialLink
