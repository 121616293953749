import * as React from "react"
import styled from "styled-components"

import Copyright from "./copyright"
import Socials from "./socials"

const FooterContainer = styled.div`
  position: absolute;
  height: 100px;
  background: linear-gradient(to top right, #b7e7e2, #e6f5f4);
  width: 100%;
`

const Footer = () => (
  <FooterContainer>
    <Copyright />
    <Socials />
  </FooterContainer>
)

export default Footer
