import * as React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import Seo from "./seo"
import Banner from "./banner"
import Hero from "./hero"
import Footer from "./footer"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 70vh;
  flex-wrap: wrap;
  width: 100%;
`

const Layout = ({ header, children }) => (
  <>
    <Seo title={header} />
    <Banner>
      <Hero header={header} />
    </Banner>
    <Container>{children}</Container>
    <Footer />
  </>
)

Layout.propTypes = {
  isIndex: PropTypes.bool,
  header: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default Layout
