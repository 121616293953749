import * as React from "react"
import styled from "styled-components"

const CopyText = styled.p`
  display: inline-block;
  padding: 20px;
  color: #888;
`

const Copyright = () => (
  <CopyText>© {new Date().getFullYear()} Detail Designs</CopyText>
)

export default Copyright
